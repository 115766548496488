<template>
  <div class="g-section mrt36">
    <div class="g-header">
      <h3>Java 语言讲解 并提供三大主流语言解法答案</h3>
      <p>Java语言讲解，IDE中书写，提供C++，Python对应代码</p>
    </div>
    <div class="container">
      <el-row align="center" class="lan-rows" type="flex">
        <el-col :span="8">
          <img alt="" src="@/assets/images/timu/java.png" />
          <div class="txt">Java语言</div>
        </el-col>
        <el-col :span="8">
          <img alt="" src="@/assets/images/timu/cc.png" />
          <div class="txt">C++</div>
        </el-col>
        <el-col :span="8">
          <img alt="" src="@/assets/images/timu/jj.png" />
          <div class="txt">Python</div>
        </el-col>
      </el-row>
      <!-- <div class="ga-tip">
        注：解法答案已完善90%，Java 全部有，C++ & Python 仅差几十题
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "javaExplain",
};
</script>
<style scoped>
@import url("../../../assets/css/courses/common.css");
</style>
